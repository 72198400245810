import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Modal, ModalBody } from "reactstrap";
import { YMInitializer } from 'react-yandex-metrika';
import StandClient from "client";
import Cart from "components/ui/Cart";

const Topbar = (props) => {
    let location = useLocation();
    const [configure, setConfigure] = useState(null);
    const [navLinks, setNavLinks] = useState([]);

    const [state, setState] = useState({
        isOpen: false,
        dropdownOpenAuth: false,
        wishlistModal: false,
    })

    useEffect(() => {
        const conf = StandClient.getFrontConfigureLocal()
        setConfigure(conf)
        if (StandClient.getConfigValueByName('show_categories_menu_in_header') === '1') {
            let categoryNavLinks = {
                title: 'Категории',
                link: '/',
                child: []
            }
            conf.data.allCategorys.forEach((element)=>{
                if (element.parent === null) {
                    var lnk = {
                        title: element.name,
                        link: '/category/'+element.id+'/',
                    };
                    var childs = conf.data.allCategorys.filter((selement)=>{
                        return (selement.parent !== null && selement.parent.id === element.id)
                    });
                    if (childs.length > 0) {
                        lnk.child = [];
                        childs.forEach((sel)=>{
                            var slnk = {
                                title: sel.name,
                                link: '/category/'+sel.id+'/',
                            };
                            lnk.child.push(slnk);
                        });
                    }
                    categoryNavLinks.child.push(lnk);
                }
            });
            setNavLinks(prevArray => [...prevArray, categoryNavLinks])
        }
        conf.data.getFrontConfigure.navLinks.forEach((n) => {
            let lnk = {
                title: n.title,
                link: n.link,
            };
            if (n.sub.length>0) {
                lnk.child = [];
                n.sub.forEach((n2) => {
                    var lnk2 = {
                        title: n2.title,
                        link: n2.link,
                    };
                    lnk.child.push(lnk2);
                });
            }
            setNavLinks(prevArray => [...prevArray, lnk])
        });
    }, []);

    const toggleWishlistModal = () => {
        setState(prev => {
            return {
                ...prev,
                wishlistModal: !prev.wishlistModal
            }
        })
    };

    const toggleLine = () => {
        setState(prev => {
            return {
                ...prev,
                isOpen: !prev.isOpen
            }
        })
    };

    // TODO: не верю, что работает
    const activateParentDropdown = (item) => {
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active");
            const parent1 = parent.parentElement;
            parent1.classList.add("active");
            if (parent1) {
                const parent2 = parent1.parentElement;
                parent2.classList.add("active");
                if (parent2) {
                    const parent3 = parent2.parentElement;
                    parent3.classList.add("active");
                    if (parent3) {
                        const parent4 = parent3.parentElement;
                        parent4.classList.add("active");
                    }
                }
            }
        }
    };

    useEffect(() => {
        let matchingMenuItem = null;
        let ul = document.getElementById("top-menu");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }

    }, []);

    const openBlock = (level2_id) => {
        let tmpLinks = navLinks;
        tmpLinks.map((tmpLink) =>
            tmpLink.id === level2_id
                ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
                : false
        );
        setNavLinks(tmpLinks)
    };

    const openNestedBlock = (level2_id, level3_id) => {
        let tmpLinks = navLinks;
        tmpLinks.map((tmpLink) =>
            tmpLink.id === level2_id
                ? tmpLink.child.map((tmpchild) =>
                tmpchild.id === level3_id
                    ?
                    (tmpchild.isOpenNestedSubMenu = !tmpchild.isOpenNestedSubMenu)
                    : (tmpchild.isOpenNestedSubMenu = false)
                )
                : false
        );
        setNavLinks(tmpLinks)
    };


    // TODO: Подумать над новой метрикой, эта устарела
    const messageWeAreNotWorkingNow = StandClient.getConfigValueByName('message_we_are_not_working_now');
    const siteDisabledByManager = StandClient.getConfigValueByName('site_disabled_by_manager');
    const yandexMetricaAccountStr = StandClient.getConfigValueByName('yandex_metrica_account');
    let yandexMetricaAccount = null;
    if (yandexMetricaAccountStr !== '') {
        try {
            yandexMetricaAccount = parseInt(yandexMetricaAccountStr, 10);
        } catch (error) {
            yandexMetricaAccount = null;
        }
    }

    return (
        <React.Fragment>
            {props.tagline ? props.tagline : null}
            { (yandexMetricaAccount==='') ? (null) : (
                <YMInitializer accounts={[yandexMetricaAccount]} options={{webvisor: true}} version="2"/>
            ) }
            <header id="topnav" className="defaultscroll sticky">
                <Container>
                    <div>
                        {(siteDisabledByManager === '1') ? (
                            <div style={{ backgroundColor: '#ffffffaa', textAlign:"center", padding:4, borderRadius:5 }}><b>{messageWeAreNotWorkingNow}</b></div>
                        ) : (null) }
                        <Link to="/" className="logo">
                            {configure?.data.getFrontConfigure.logoIcon === '' || configure?.data.getFrontConfigure.logoIcon === 'null' ? (null) : (
                                    <img src={configure?.data.getFrontConfigure.logoIcon} alt={"Logo"} width={64} height={64} style={{margin:8,marginRight:16}} />
                            )}
                            {configure?.data.getFrontConfigure.title}
                        </Link>
                    </div>
                    <div className="menu-extras">
                        <div className="menu-item">
                            <Link
                                to="#"
                                onClick={toggleLine}
                                className={state.isOpen ? "navbar-toggle open" : "navbar-toggle"}
                            >
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <Cart />
                    <div id="navigation" style={{ display: state.isOpen ? "block" : "none" }}>
                        <ul className="navigation-menu" id="top-menu">
                            {navLinks.map((navLink, key) =>
                                navLink.child ? (
                                    <li className="has-submenu" key={key}>
                                        <a
                                            href={navLink.link}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                openBlock(navLink.id);
                                            }}
                                        >
                                            {navLink.title}
                                        </a>
                                    <span className="menu-arrow"></span>
                                    {navLink.isMegaMenu ? (
                                        <ul className={ navLink.isOpenSubMenu ? "submenu megamenu open" : "submenu megamenu" }>
                                            <li>
                                                <ul>
                                                    {navLink.child.map((item, childKey) =>
                                                        item.id < 12 ? (
                                                            <li key={childKey}>
                                                                <a href={item.link}>
                                                                    {item.title}
                                                                </a>
                                                            </li>
                                                        ) : null)
                                                    }
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    {navLink.child.map((item, childKey) =>
                                                        item.id < 23 && item.id > 11 ? (
                                                            <li key={childKey}>
                                                                <Link to={item.link}>
                                                                    {item.title}
                                                                    {item.isNew ? (
                                                                        <span className="badge bg-danger rounded ms-2">
                                                                            new
                                                                        </span>
                                                                    ) : null}
                                                                </Link>
                                                            </li>
                                                        ) : null)
                                                    }
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    {navLink.child.map((item, childKey) =>
                                                        item.id < 34 && item.id > 22 ? (
                                                            <li key={childKey}>
                                                                <Link to={item.link}>
                                                                    {item.title}
                                                                    {item.isNew ? (
                                                                        <span className="badge bg-danger">
                                                                            new
                                                                        </span>
                                                                    ) : null}
                                                                    {item.isOnePage ? (
                                                                        <span className="badge bg-warning rounded ms-2">
                                                                            Onepage
                                                                        </span>
                                                                    ) : null}
                                                                    {item.isupdatePage ? (
                                                                        <span className="badge badge-pill bg-info">
                                                                            Updated
                                                                        </span>
                                                                    ) : null}
                                                                </Link>
                                                            </li>
                                                        ) : null)
                                                    }
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                {navLink.child.map((item, childKey) =>
                                                    item.id < 45 && item.id > 33 ? (
                                                        <li key={childKey}>
                                                            <Link to={item.link}>
                                                                {item.title}
                                                                {item.isOnePage ? (
                                                                    <span className="badge bg-warning rounded ms-2">
                                                                        Onepage
                                                                    </span>
                                                                ) : null}
                                                                {item.isupdatePage ? (
                                                                    <span className="badge badge-pill bg-info">
                                                                        Updated
                                                                    </span>
                                                                ) : null}
                                                            </Link>
                                                        </li>
                                                    ) : null)
                                                }
                                                </ul>
                                            </li>
                                            <li>
                                                <ul>
                                                    {navLink.child.map((item, childKey) =>
                                                        item.id < 56 && item.id > 44 ? (
                                                            <li key={childKey}>
                                                                <Link to={item.link}>
                                                                    {item.title}
                                                                    {item.isOnePage ? (
                                                                        <span className="badge bg-warning rounded ms-2">
                                                                            Onepage
                                                                        </span>
                                                                    ) : null}
                                                                    {item.isupdatePage ? (
                                                                        <span className="badge badge-pill bg-info">
                                                                            Updated
                                                                        </span>
                                                                    ) : null}
                                                                </Link>
                                                            </li>
                                                        ) : null)
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul className={ navLink.isOpenSubMenu ? "submenu open" : "submenu" } >
                                            {navLink.child.map((childArray, childKey) =>
                                                childArray.nestedChild ? (
                                                <li className="has-submenu" key={childKey}>
                                                    <Link
                                                        to={childArray.link}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            openNestedBlock(
                                                                navLink.id,
                                                                childArray.id
                                                            );
                                                        }}
                                                    >
                                                        {childArray.title}{" "}
                                                        {childArray.isNew ? (
                                                            <span className="badge badge-pill badge-success">
                                                                Added
                                                            </span>
                                                        ) : null}
                                                    </Link>
                                                    <span className="submenu-arrow"></span>
                                                    <ul className={ childArray.isOpenNestedSubMenu ? "submenu open" : "submenu" } >
                                                        {childArray.nestedChild.map(
                                                            (nestedChildArray, nestedKey) => (
                                                            <li key={nestedKey}>
                                                                <Link to={nestedChildArray.link}>
                                                                    {nestedChildArray.title}{" "}
                                                                    {nestedChildArray.isNewPage ? (
                                                                        <span className="badge badge-danger rounded">
                                                                            NEW
                                                                        </span>
                                                                    ) : null}
                                                                    {nestedChildArray.isupdatePage ? (
                                                                        <span className="badge badge-pill badge-info">
                                                                            Updated
                                                                        </span>
                                                                    ) : null}
                                                                </Link>
                                                            </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                                ) : (
                                                <li className={(childArray.child)?("has-submenu"):("")} key={childKey}>
                                                    <a href={childArray.link}>
                                                        {childArray.title}
                                                    </a>
                                                    {(childArray.child)?(<span className="submenu-arrow"></span>):(null)}
                                                    {(childArray.child)?(
                                                        <ul className={childArray.isOpenNestedSubMenu ? "submenu open" : "submenu"} >
                                                            {childArray.child.map(
                                                                (nestedChildArray, nestedKey) => (
                                                                    <li key={nestedKey}>
                                                                        <a href={nestedChildArray.link}>
                                                                            {nestedChildArray.title}
                                                                        </a>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ):(null)}
                                                </li>
                                                )
                                            )}
                                        </ul>
                                    )}
                                    </li>
                                ) : (
                                    <li key={key}>
                                        <a href={navLink.link}>{navLink.title}</a>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </Container>
            </header>

            <Modal
                isOpen={state.wishlistModal}
                tabIndex="-1"
                centered
                contentClassName="rounded shadow-lg border-0 overflow-hidden"
                toggle={toggleWishlistModal}
            >
            <ModalBody className="py-5">
                <div className="text-center">
                    <div
                        className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                        style={{ height: "95px", width: "95px" }}
                    >
                        <h1 className="mb-0">
                            <i className="uil uil-heart-break align-middle"></i>
                        </h1>
                    </div>
                    <div className="mt-4">
                        <h4>Your wishlist is empty.</h4>
                        <p className="text-muted">
                            Create your first wishlist request...
                        </p>
                        <div className="mt-4">
                            <Link to="#" className="btn btn-outline-primary">
                                + Create new wishlist
                            </Link>
                        </div>
                    </div>
                </div>
            </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default Topbar;
