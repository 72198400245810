import ScrollUpButton from "react-scroll-up-button";
import FeatherIcon from "feather-icons-react";
import Topbar from "./Topbar";
import Footer from "./Footer";
import StandClient from "client";

const Layout = (props) => {
    const topbarViewOff = StandClient.getConfigValueByName('topbar_view_off')
    const footerViewOff = StandClient.getConfigValueByName('footer_view_off')

    return (
        <>
            {!topbarViewOff && <Topbar hasDarkTopBar={props.hasDarkTopBar} />}
                {props.children}
            {!footerViewOff && <Footer />}
            <ScrollUpButton
                ContainerClassName="classForContainer"
                style={{ height: 36, width: 36 }}
                TransitionClassName="classForTransition"
            >
                <FeatherIcon icon="arrow-up" className="icons" />
            </ScrollUpButton>
      </>
    );
}

export default Layout;
